import { FaCheckCircle } from "react-icons/fa";
import { QuestionType } from '../types';

interface Props {
    questionNumber: number,
    current: QuestionType,
    answers: {
        [key: string]: any},
    setAnswer: any
}

const Question = (props: Props) => {
    const { current, answers, setAnswer, questionNumber} = props;
    
    /**
     * set answers of current question to state4
     */
    const handleClick = (index: number) => {
        setAnswer({
        ...answers, [questionNumber]: index
        });
    };

    return (
        <>
            {current.options.map((option, index) => {
                return (
                  <li key={index} onClick={() => handleClick(index)}>
                    <span className={`option ${answers[questionNumber] === index && 'active'}`}>{option}</span>
                    {answers[questionNumber] === index && <FaCheckCircle />}
                  </li>
                );
              })}
        </>
    );
};

export default Question;