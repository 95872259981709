import { useEffect, useState } from 'react';
import { ThreeDots } from  'react-loader-spinner';

import Question from './compoonents/Question';
import { QuestionType } from './types';
import './App.scss';
import data from './data/questions.json';

const App = () => {
  const [questionNumber, setQuestionNumber] = useState<number>(0);
  const [current, setCurrent] = useState<QuestionType>({
    id: data[0].id,
    question: data[0].question,
    options: data[0].options,
  });
  const [answers, setAnswers] = useState<{ [key: string]: any }>({});
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  /**
   * Update current question to display
   */
  useEffect(() => {
    setError('');
    setCurrent({
      ...current,
      id: data[questionNumber].id,
      question: data[questionNumber].question,
      options: data[questionNumber].options
    });
  }, [questionNumber]);

  /**
   * Clear error if answer is selected
   */
  useEffect(() => {
    setError('');
  }, [answers]);



  /**
   * Go to next question 
   */
  const nextQuestion = () => {
    if (answers[questionNumber] >= 0) {
      if (questionNumber < data.length - 1) setQuestionNumber(prevState => prevState + 1);
    }
    else setError('* Please select an option');
  };

  /**
   * Go to previous question 
   */
  const prevQuestion = () => {
    if (questionNumber > 0) setQuestionNumber(prevState => prevState - 1);
  };

  /**
   * Simulate a questionnaire submission
   */
  const submit = () => {
    if (answers[questionNumber] >= 0) {
      setSubmitting(true);
      const form = new FormData();

      // Create form data to submit
      for (const [key, value] of Object.entries(answers)) {
        form.append(`question${key}`, `${value}`);
      }
      
      // Simulate POST request
      setTimeout(() => {
        setSubmitting(false);
        // Make api call and pass in form data here

        
        setSubmitted(true);
      }, 1000);
    }
  };

  /**
   * Reset answers and return to first question
   */
  const restart = () => {
    setQuestionNumber(0);
    setAnswers({});
    setSubmitted(false);
  };

  return (
    <div className="App">
      <div className="container">
        {!submitted && 
          <div className="questionnaire">
            <p>{current.question} <span>({questionNumber}/{data.length - 1})</span></p>
            <p className="error">{error !== '' && error}</p>

            <ul>
              <Question 
                questionNumber={questionNumber}
                current={current}
                answers={answers}
                setAnswer={setAnswers}
              />
            </ul>
            <div className="button-wrapper">
              {questionNumber > 0 && <button onClick={prevQuestion}>Back</button>}
              {(questionNumber < data.length - 1) && <button onClick={nextQuestion}>Next</button>}
              {(questionNumber === data.length - 1) && <button onClick={submit}>
                {submitting ? <ThreeDots color="#fff" height={20} width={20} /> : 'Submit'}
              </button>}
            </div>
          </div>
        }

        {submitted && 
          <div className="">
            <p>Thank you for your submission!</p>
            <button  onClick={restart}>Restart</button>
          </div>
        }
      </div>
    </div>
  );
}

export default App;
